<template>
    <layout>
        <page title="Manage Inventory">
            <template v-slot:header-content>
                <input v-model="search" @keyup="getList()" class="form-control form-control-sm mr-10" placeholder="Search">
                <router-link to="/inventory/adjust" class="btn btn-primary btn-sm mr-5">⚙️ Stock Adjustment</router-link>
                <router-link to="/inventory/history" class="btn btn-danger btn-sm mr-5">📜 Stock History</router-link>
            </template>
            <table class="table table-hover table-outer-bordered font-size-12">
                <thead>
                    <tr>
                        <th width="10%">🗓 DATE</th>
                        <th width="35%">⚒ ITEM</th>
                        <th width="20%" class="text-center">🛒 BALANCE STOCK</th>
                        <th width="20%" class="text-center">🗄 STOCK LIMITER</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in data.data" :key="item.id">
                        <td>{{ (item.inventory) ? item.inventory.beginning_date : '' }}</td>
                        <td>
                            {{ item.name }}
                            <div class="text-muted" style="font-size: 1.1rem;">
                                {{ item.details }} <span v-if="item.size" class="text-success font-weight-semi-bold">({{ item.size }})</span>
                            </div>
                        </td>
                        <td class="text-center"><b>{{ (item.inventory) ? item.inventory.balance_stock : 0 }}</b></td>
                        <td class="text-center">{{ (item.limiter) ? item.limiter : '' }}</td>
                    </tr>
                </tbody>
            </table>
            <template v-slot:pagination>
                <pagination
                    v-if="data.links && data.links.length > 3"
                    :links="data.links"
                    :first_page_url="data.first_page_url"
                    :last_page_url="data.last_page_url"
                    :prev_page_url="data.prev_page_url"
                    :next_page_url="data.next_page_url"
                    v-on:click="getList"
                ></pagination>
            </template>
        </page>
    </layout>
</template>

<script>
import Page from "../../components/Page";
import Pagination from "../../components/Pagination";

export default {
    components: {
        Page,
        Pagination
    },
    data() {
        return {
            data: [],
            search: ''
        }
    },
    created() {
        this.getList();
    },
    methods: {
        getList(link = null) {
            let newLink = link || `api/inventories?page=1`;
            this.$http.get(`${newLink}&search=${this.search}`).then((response) => {
                this.data = response.data.list;
                console.log(this.data);
            });
        }
    }
}
</script>
